import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'

import close from "../../images/dashboard/modal-close.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import UnsubscribedListModal from './UnsubscribedListModal';
import { emailRecipientsList } from '../../helper/service';
import UserReportModalList from './UserReportModalList';

const EmailDetail = (props) => {
    const [isOpen, setisOpen] = useState(true);
    const [subject, setSubject] = useState('');
    const [dateSent, setDateSent] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [scheduleDate, setScheduleDate] = useState(false);
    const [recipientsQnt, setRecipientQnt] = useState(0);
    const [unsubscribeUsers, setUnsubscribeUsers] = useState(0);
    const [attachments, setAttachments] = useState('');
    const [attachmentURL, setAttachmentUrl] = useState('');
    const [unsubscribeOpen, setUnsubscribeOpen] = useState(false);
    const [unsubsList, setUnsubsList] = useState(null);
    const [showUserReportModal, setShowUserReportModal] = useState(false);
    const [recipientUsers, setRecipientUsers] = useState(null);
    const [sentEmailDate, setSentEmailDate] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    useEffect(() => {
        if (props.emailDetail) {
            const email = props.emailDetail
            setSubject(email.emailSubject);
            setEmailContent(email.emailMessage);
            const recptCount = email?.recipientsMember?.length > 0 ? email?.recipientsMember?.length : 0;
            setRecipientQnt(recptCount);
            setUnsubscribeUsers(email?.unsubscribeUsersList?.length ?? 0);
            setUnsubsList(email?.unsubscribeUsersList)
            if (!email.emailSchedule) {
                const newDate = converDate(email.createdAt);
                setDateSent(newDate);
            } else {
                const newDate = converDate(email.emailSchedule);
                setDateSent(newDate);
                setScheduleDate(true);
            }
            if (email?.files) {
                setAttachmentUrl(email?.files);
                const fileName = email?.files?.split("/").pop()?.split(".")[0] || "";
                const fileExtension = email?.files?.split("/").pop()?.split(".")[1] || "";
                const maxLength = 75;
                var attachmentName = '';
                if (fileName.length <= maxLength) {
                    attachmentName = fileName + '.' + fileExtension;
                } else {
                    const start = fileName.substring(0, 50);
                    const end = fileName.substring(fileName.length - 7);
                    attachmentName`${start}...${end}.${fileExtension}`;
                }
                setAttachments(attachmentName);
            } else {
                setAttachments('');
                setAttachmentUrl('');
            }
        }

    }, [props.emailDetail])

    const handleUnsubscribeMember = () => {
        setisOpen(false);
        setUnsubscribeOpen(true);
    }
    const handleShowMember = () => {
        let emailData = props?.emailDetail;
        emailRecipientsList(emailData?.recipientsMember).then((user) => {
            setRecipientUsers(user.result)
            if(!emailData?.emailSchedule || emailData?.emailSchedule=="" || new Date(emailData?.emailSchedule)<=new Date()){
                setEmailMessage("Sent email recipients")
                setSentEmailDate(emailData?.emailSchedule ? emailData.emailSchedule.split("T")[0] : emailData.createdAt.split("T")[0])
            }
            else if( emailData.emailSchedule && new Date(emailData?.emailSchedule)>new Date()){
                setEmailMessage("Schedule email recipients")
                setSentEmailDate(emailData.emailSchedule.split("T")[0])
            }
           
            setisOpen(false);
            setShowUserReportModal(true)
        })

    }
    const handleCloseModal = () => {
        setisOpen(false);
        props.onClose();
    };
    const handleCloseReportModal = () => {
        setisOpen(false);
        props.onClose();
        setShowUserReportModal(false)

    };

    const converDate = (date) => {
        const originalDate = new Date(date);
        const selectedTimeZone = localStorage.getItem('websiteTimeZone');
        const convertedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone, hour12: true });
        var sentDate = new Date(convertedDate);
        var textData = "";
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        let strtDate = convertedDate.split('/')
        var months = parseInt(strtDate[0] - 1);
        textData = (strtDate[1]) + "-" + monthNames[months] + "-" + sentDate.getUTCFullYear();
        return textData;
    }

    return (
        <>
            <Modal
                className="wis-modal modal-wide email-detail-modal"
                show={isOpen}
                onHide={handleCloseModal}
                backdrop="static">
                <ModalHeader>
                    <h5 className="modal-title pl-0">
                        Email Detail
                    </h5>
                    <button
                        type="button"
                        className="close ml-5"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                    >
                        <span aria-hidden="true">
                            <img src={close} />
                        </span>
                    </button>

                </ModalHeader>
                <ModalBody>
                    <div className='d-flex justify-content-center align-items-center mb-4'>
                        <table>
                            <tr className='mb-2'>
                                <td style={{ cursor: "pointer" }} onClick={recipientsQnt > 0 ? () => handleShowMember() : null}>Qty Recipients</td>
                                {/* <td>Open Rate</td> */}
                                <td style={{ borderRight: 'transparent', cursor: "pointer" }} onClick={unsubscribeUsers > 0 ? () => handleUnsubscribeMember() : null}>Unsubscribed</td>
                            </tr>
                            <tr>
                                <td>{recipientsQnt}</td>
                                {/* <td>100%</td> */}
                                <td style={{ borderRight: 'transparent' }}>{unsubscribeUsers}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='form-group'>
                        <label>{scheduleDate ? "Scheduled on:" : 'Sent on:'}</label>
                        <div className='fs-6 mt-2'>{dateSent}</div>
                    </div>
                    <div className='form-group'>
                        <label>Subject:</label>
                        <div className='fs-6 mt-2'>{subject}</div>
                    </div>
                    <div className='form-group'>
                        <label>Message:</label>
                        <div className='email-content'>
                            <div dangerouslySetInnerHTML={{ __html: emailContent }} />
                            {attachmentURL &&
                                <div className='border-top mt-4'>
                                    <label>Attchments:</label>
                                    <a
                                        href={attachmentURL}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={attachmentURL.substring(attachmentURL.lastIndexOf('/') + 1)}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <span className='word-break'>{attachments}</span>
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: '16px' }} />
                                    </a>
                                </div>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {unsubscribeOpen && <UnsubscribedListModal recipientCount={recipientsQnt} unsubsList={unsubsList} onClose={() => { setUnsubscribeOpen(false); handleCloseModal() }} />}
            {showUserReportModal &&
                <UserReportModalList recipientUsers={recipientUsers} sentEmailDate={sentEmailDate}
                    handleCloseReportModal={handleCloseReportModal} emailMessage={emailMessage} />
            }
        </>
    )
}

export default EmailDetail